const namespaced = true
import api from './../../api/auth.js';
import router from '../../routes/index.js';

const state = {
    cantidad : []
}
const mutations = {
    MUTATION_SET_CONCEPTS(state, data) {
        state.cantidad = data;
    },
    MUTATION_CLEAR_CONCEPTS(state) {
        state.cantidad = [];
    }
}
const actions = {
    getCantidad(context) {
        api.getCantidad().then(function (res) {
            context.commit('MUTATION_SET_CONCEPTS', res.data);

        }).catch(function (error) {
            console.log(error)
            router.push({ name: "login" });

        })
    },

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}