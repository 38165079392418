const namespaced = true
import api from './../../api/concepts.js';
import router from '../../routes/index.js';

const state = {
     concepts: [

     ]

}
const mutations = {
     MUTATION_SET_CONCEPTS(state, data) {
          state.concepts = data;
     },
     MUTATION_CLEAR_CONCEPTS(state) {
          state.concepts = [];
     }
}
const actions = {
     getConcepts(context) {
          api.getConcepts().then(function (res) {
               context.commit('MUTATION_SET_CONCEPTS', res.data);

          }).catch(function (error) {
               console.log(error)
               router.push({ name: "login" });
          })
     },
     updateConcepts(context, { ConceptoId, Concepto, Importe }) {
          api.updateConcepts({ 'ConceptoId': ConceptoId, 'Concepto': Concepto, 'Importe': Importe }).then(function (res) {
               if (res.status == 200) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
               }

          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },
     createConcepts(context, { concepto, importe }) {
          api.createConcepts({  'concepto': concepto, 'importe': importe }).then(function (res) {
               if (res.status == 201) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                    context.dispatch('getConcepts');

               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },
     deleteConcepts(context, { ConceptoId }) {
          api.deleteConcepts({  'ConceptoId': ConceptoId }).then(function (res) {
               if (res.status == 200) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                    context.dispatch('getConcepts');

               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },
     
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}   
