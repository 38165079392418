function getConcepts() {
    return window.axios.get('/api/concepto');
}
function updateConcepts({ConceptoId,Concepto,Importe}){
    return window.axios.patch('/api/concepto/update/'+ ConceptoId,{'Concepto': Concepto,'Importe': Importe});
}
function createConcepts({concepto,importe}){
    return window.axios.post('/api/concepto/create', {'Concepto': concepto,'Importe': importe});
}
function deleteConcepts({ConceptoId}){
    return window.axios.delete('api/concepto/delete/'+ ConceptoId);
}
export default {
    updateConcepts,
    getConcepts,
    createConcepts,
    deleteConcepts
}