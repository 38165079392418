function getTickets(page){
    return window.axios.get('/api/ticket/list?page='+page);
}
function getAdvancedSearch(NoEconomico){
    return window.axios.get('/api/ticket/search/'+NoEconomico);
}
function updateTickets({TicketId,ConcesionarioId,NoEconomico,Observaciones,Concepto}){
    return window.axios.patch('/api/ticket/update/'+ TicketId,{'ConcesionarioId': ConcesionarioId,'NoEconomico': NoEconomico,'Observaciones': Observaciones,'Concepto': Concepto});
}
function createTickets({ConcesionarioId,NoEconomico,Observaciones,Concepto}){
    return window.axios.post('/api/ticket/create',{'ConcesionarioId': ConcesionarioId,'NoEconomico': NoEconomico,'Observaciones': Observaciones,'Concepto': Concepto  })
}
function deleteTickets({TicketId}){
    return window.axios.delete('/api/ticket/delete/'+ TicketId);
}
function printTickets({TicketId}){
    return window.axios.get('/api/ticket/reporte/'+ TicketId);
}
function getOrden(){
    return window.axios.get('/apit/orden/');
}
export default {
    getTickets,
    updateTickets,
    createTickets,
    deleteTickets,
    printTickets,
    getOrden,
    getAdvancedSearch
}

 