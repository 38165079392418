const namespaced  = true;

export const state  = {
    notification :
    {
        'color' : 'success',
        'visibility' : false,
        'timeout' : 5000,
        'text' : ''
    }
}

const mutations  = {
    PUSH(state, notification){
        state.notification = notification;
    },

    DELETE (state, notificationToRemove){
        state.notification = state.notification.filter(
            notification => notification.id !== notificationToRemove.id
        )
    },
    SET_NOTIFICATION (state, notificationToRemove){
        state.notification = state.notification.filter(
            notification => notification.id !== notificationToRemove.id
        )
    },
    CLEAR (state){
        state.notification = {'color' : 'success', 'visibility' : false, 'timeout' : 5000, 'text' : ''}
    }
}

const actions  = {
    add (context, notification){
        context.commit('PUSH', notification);
    },
    remove (context, notificationToRemove){
        context.commit('DELETE', notificationToRemove);
    },
    clear (context){
        context.commit('CLEAR');
    },


}

const getters  = {

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
