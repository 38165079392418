const namespaced = true
import api from './../../api/login.js';
import router from '../../routes/index.js';

const state = {
     login: [

     ]

}
const mutations = {
     MUTATION_SET_CONCEPTS(state, data) {
          state.login = data;
     },
     MUTATION_CLEAR_CONCEPTS(state) {
          state.login = [];
     }
}
const actions = {
    loginUser(context, { email, password}) {
          api.loginUser({'email': email, 'password': password }).then(function (res) {
               if (res.status == 200) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                    router.push({ name: "home" });
               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },
     logoutUser(context) {
          api.logoutUser().then(function (res) {
               if (res.status == 200) {
                    router.push({ name: "login" });
               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },     
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}   
