const namespaced = true

const state = {
    configuration : {
        themeDark: false      
    }
}

const mutations = {
    SET_CONFIGURATION(state, input_data) {
        state.configuration = input_data;
    },
    SET_THEME_MODE(state, input_data) {
        state.configuration.themeDark = input_data;
    },
}

const actions = {
    resetConfiguration(context){
        context.commit('SET_CONFIGURATION', { themeDark: false });
    },
    setDarkMode(context, value) {
        if (value) {
            context.commit('SET_THEME_MODE', false);
        } else {
            context.commit('SET_THEME_MODE', true);
        }
    },
    
}

const getters = {
    
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}