const namespaced = true

import api     from '../../api/abilities.js';

const state = {
    all_abilities : [],
}
const mutations = {
     MUTATION_SET_ABILITIES(state, value) {
          state.all_abilities = value;
     },
    
}
const actions = {
      // Name Property
     getAbilities(context) {
          return new Promise((resolve ) => { 
               api.getAbilities().then(function (res) {
                    context.commit('MUTATION_SET_ABILITIES', res.data.data);
                    resolve(res.data.data);
               }).catch(function () {
                    
               })
          })
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
