// import * as firebase from 'firebase';
// import "firebase/auth";

import api from '../../api/authentication.js';
import router from '../../routes/index.js';

const namespaced = true

const state = {
    iaccept: false,
    isLoggedIn: false, 
    userAuth: {
        name: '',
        email: '',
        photoUrl: null, 
        emailVerified: false,
        uid: ''
    },
    showLogoutDialog : false,
    providerParameters : {
        prompt : 'select_account'
    }
}

const mutations = {
    MUTATION_SET_USER(state, value) {
        state.userAuth = value;
    },
    MUTATION_SET_LOGOUT(state, value) {
        state.showLogoutDialog = value;
    },
    SET_LOGINSTATUS(state, input_data){
        state.isLoggedIn = input_data;
    },
}

const actions = {
    login_server(context, data) {
        return new Promise((resolve) => { 
            api.login_server(data).then(function (res) {
                context.commit('SET_LOGINSTATUS', true);
                resolve(res);
            }).catch(function (error) {
                context.commit('SET_LOGINSTATUS', false);
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error.response.data.data.message}, {root: true });
                router.push({'name' : 'login'});
            })
        })        
   },
   redirect(context){
    context.commit('SET_LOGINSTATUS', false);
    console.log("Prueba")
    router.push({'name' : 'login'});
   }  

    /**
     * Firebase Login
     */
    // login(context) {
    //     const provider = new firebase.auth.GoogleAuthProvider();         
    //     provider.setCustomParameters(context.state.providerParameters);

    //     firebase.auth().signInWithPopup(provider).then((result) => {
    //         var user = firebase.auth().currentUser;
    //         if (user != null) {                

    //             api.login({ email: user.email, image:user.photoURL })
    //                 .then(function (res) {
    //                     context.dispatch('configuration/setRoleUser', res.data.role, {root: true })
    //                     context.dispatch('configuration/setLoginStatus', true, {root: true })
    //                     context.dispatch('configuration/setSubscriptionType', res.data.subscription_type, {root: true })
    //                     context.commit('MUTATION_SET_USER', { 'name': user.displayName, 'email': user.email, 'photoUrl': user.photoURL, 'emailVerified': user.emailVerified, 'uid': user.uid})
    //                     router.push({ name: "search" });
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error)
    //                 })
    //         } else {
    //             context.commit('MUTATION_SET_USER', {'name': '', 'email': '', 'photoUrl': null, 'emailVerified': false, 'uid': ''})
    //             context.dispatch('configuration/setLoginStatus', false, {root: true })
    //         }
    //         return result;
    //     }, function (err) {
    //         context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : err.message}, {root: true })            
    //     });
    // },
    /**
     * Firebase Create Account by Gmail
     */
    // async registerByGoogle(context) {
    //     var auth = firebase.auth();
    //     const provider = new firebase.auth.GoogleAuthProvider();         
    //     provider.setCustomParameters(context.state.providerParameters);

    // await firebase.auth().signInWithPopup(provider).then((result) => {
    //     var user = null;
    //     user = result.user;
    //     if (user != null) {
    //         api.registerByGoogle({ 'name': user.displayName, 'email': user.email, 'photoUrl': user.photoURL, 'emailVerified': user.emailVerified, 'uid': user.uid}).then(function (res) {            
    //                 if(res.data.code == 1000){

    //                     context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : 'Este correo ya ha sido utilizado para registrarse'}, {root: true });

    //                 }else if(res.data.code == 200){

    //                     api.login({ email: user.email }).then(function (ret) {
    //                         context.dispatch('configuration/setRoleUser', ret.data.role, {root: true })
    //                         context.dispatch('configuration/setLoginStatus', true, {root: true })
    //                         context.dispatch('configuration/setSubscriptionType', ret.data.subscription_type, {root: true })
    //                         context.commit('MUTATION_SET_USER', { 'name': user.displayName, 'email': user.email, 'photoUrl': user.photoURL, 'emailVerified': user.emailVerified, 'uid': user.uid})
    //                         router.push({ name: "search" });

    //                 }).catch(function (error) {
    //                     console.log(error)
    //                 })

    //                 }else{
    //                     context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : 'Excepción no controlada'}, {root: true });
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log(error)
    //             })
    //     } else {
    //         context.commit('MUTATION_SET_USER', {'name': '', 'email': '', 'photoUrl': null, 'emailVerified': false, 'uid': ''})
    //         context.dispatch('configuration/setLoginStatus', false, {root: true })
    //     }
    //     return result;
    //     }).catch((error) => {
    //         context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : error.message}, {root: true });
    //     });
        
    // },
    /**
     * Firebase LogOut
     */
    // logout(context) {
    //     context.commit('MUTATION_SET_LOGOUT', true)
    //     firebase.auth().signOut().then(function () {            
    //         api.logout().then(function (res){
    //             context.commit('MUTATION_SET_USER', {'name': '', 'email': '', 'photoUrl': null, 'emailVerified': false, 'uid': ''})
    //             context.dispatch('configuration/setRoleUser', -1, {root: true })
    //             context.dispatch('configuration/setLoginStatus', false, {root: true })
    //             context.dispatch('configuration/setSubscriptionType', -1, {root: true })
    //             context.commit('MUTATION_SET_LOGOUT', false)
    //         }).catch(function (error){
                
    //         })
            
    //     }, function (error) {
    //         router.push({ name: "login" });
    //     });
    // },
    /**
     * Firebase Create Account by Email & Password
     */
    // createAccount(context,data){    
    //     firebase.auth().createUserWithEmailAndPassword(data.email, data.password).then((user) => {
    //         firebase.auth().onAuthStateChanged(function (user) {
    //             if (user) {
    //                 try {
    //                     api.registerByEmail(data).then(async function (res) {
    //                         await context.dispatch('notifications/add', {'color' : 'success',  'visibility' : true,  'timeout' : 5000, 'text' : 'Usuario creado correctamente, autenticarse nuevamente'}, {root: true })            
    //                         await router.push({name : "login"});

    //                     }).catch(function (erx) {
    //                         console.log(erx)
    //                     })
    //                 } catch (e) {
                        
    //                 }
    //             }
    //         });
    //         },
    //     (err) => {
    //         context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : err.message}, {root: true })            
    //     }
    //     );
    // },
    /**
     * Firebase Login By Email & Password
     */
    // loginByEmail(context,data){    
    //     firebase.auth().signInWithEmailAndPassword(data.email, data.password).then((userCredential) => {                
    //             var user = userCredential.user;

    //             api.loginByEmail({ email: data.email, password : data.password }).then(function (res) {
    //                     context.dispatch('configuration/setRoleUser', res.data.role, {root: true })
    //                     context.dispatch('configuration/setLoginStatus', true, {root: true })
    //                     context.dispatch('configuration/setSubscriptionType', res.data.subscription_type, {root: true })
    //                     context.commit('MUTATION_SET_USER', { 'name': user.displayName, 'email': user.email, 'photoUrl': user.photoURL, 'emailVerified': user.emailVerified, 'uid': user.uid})
    //                     router.push({ name: "search" });
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error)
    //                 })
                
    //         })
    //         .catch((error) => {
    //             context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : error.message}, {root: true })            
    //         });
    // },
}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}