const namespaced = true
import api from './../../api/tickets.js';
import router from '../../routes/index.js';


const state = {
    tickets : [

    ],
    ticketsSearch:[]
}
const mutations = {
     // Name, Property
    MUTATION_SET_CONCEPTS(state, data) {
        state.tickets = data;
    },
    MUTATION_CLEAR_CONCEPTS(state) {
        state.tickets = [];
    },
    MUTATION_SET_TICKET_SEARCH(state, data) {
        state.ticketsSearch = data;
    },
    MUTATION_CLEAR_TICKET_SEARCH(state) {
        state.ticketsSearch = [];
    }
}
const actions = {
     getTickets(context, page) {
        api.getTickets(page).then(function (res) {
            context.commit('MUTATION_SET_CONCEPTS', res.data);

        }).catch(function (error) {
            console.log(error)
            router.push({ name: "login" });

        })
    },
    getTicketsSearch(context, NoEconomico) {
        api.getAdvancedSearch(NoEconomico).then(function (res) {
            if (res.status == 200) {
                context.commit('MUTATION_SET_TICKET_SEARCH', res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
            }

        }).catch(function (error) {
            console.log(error)
            router.push({ name: "login" });

        })
    },
    deleteTickets(context, { TicketId }) {
        api.deleteTickets({ 'TicketId': TicketId }).then(function (res) {
            if (res.status == 200) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                context.dispatch('getTickets');
            }
        }).catch(function () {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });

        })
    },
    updateTickets(context, { TicketId,ConcesionarioId,NoEconomico,Observaciones,Concepto }) {
        api.updateTickets({ 'TicketId': TicketId, 'ConcesionarioId': ConcesionarioId,'NoEconomico': NoEconomico,'Observaciones': Observaciones,'Concepto': Concepto }).then(function (res) {
             if (res.status == 200) {
                  context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                  context.dispatch('getTickets');
             }

        }).catch(function (error) {
             context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.message }, { root: true });
        })
   },
   createTickets(context, {ConcesionarioId,NoEconomico,Observaciones,Concepto}) {
    api.createTickets({'ConcesionarioId': ConcesionarioId,'NoEconomico': NoEconomico,'Observaciones': Observaciones,'Concepto':Concepto}).then(function (res) {
         if (res.status == 200) {
              context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
              context.dispatch('getTickets');
         }
    }).catch(function () {
         context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
    })
   },
   getOrden(context) {
    api.getOrden().then(function (res) {
        context.commit('MUTATION_SET_CONCEPTS', res.data);

    }).catch(function (error) {
        console.log(error)

    })
    },
    printTickets(context, { TicketId }) {
        api.printTickets({ 'TicketId': TicketId }).then(function (res) {
            if (res.status == 200) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
            }
        }).catch(function () {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });

        })
    },

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}