const namespaced = true
import api from './../../api/concessionaires.js';
import router from '../../routes/index.js';


const state = {
    concessionaries: [

    ]
}
const mutations = {
    MUTATION_SET_CONCEPTS(state, data) {
        state.concessionaries = data;
    },
    MUTATION_CLEAR_CONCEPTS(state) {
        state.concessionaries = [];
    }
}
const actions = {
    // Name Property
    getConcessionaries(context) {
        api.getConcessionaries().then(function (res) {
            context.commit('MUTATION_SET_CONCEPTS', res.data.data);
        }).catch(function () {
            // console.log(error)
            router.push({ name: "login" });
        })
    },
    updateConcessionaries(context, { ConcesionarioId, Nombre, Ciudad }) {
        api.updateConcessionaries({ 'ConcesionarioId': ConcesionarioId, 'Nombre': Nombre, 'Ciudad': Ciudad }).then(function (res) {
            if (res.status == 200) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                context.dispatch('getConcessionaries');
            }

        }).catch(function () {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
        })
    },
    deleteConcessionaries(context, { ConcesionarioId }) {
        api.deleteConcessionaries({ 'ConcesionarioId': ConcesionarioId }).then(function (res) {
            if (res.status == 200) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                context.dispatch('getConcessionaries');
            }
        }).catch(function () {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });

        })
    },

    createConcesssionaries(context, { Nombre, Ciudad }) {
        api.createConcesssionaries({  'Nombre': Nombre, 'Ciudad': Ciudad }).then(function (res) {
             if (res.status == 201) {
                  context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                  context.dispatch('getConcessionaries');
             }
        }).catch(function () {
             context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
        })
   },



}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}