import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'login',
  },
  {
    path: '/home', 
    name: 'home',
  },
  {
    path: '/conceptos',
    name: 'conceptos',
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
  },
  {
    path: '/concesionarios',
    name: 'concesionarios',
  },
  {
    path: '/tickets',
    name: 'tickets',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/*',
    name: 'login',
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes
})

export default router