<template>
    <v-app>

        <navigation-menu v-show="nav()" :config=configuration></navigation-menu>
        <v-main>
            <router-view></router-view>
        </v-main>

        <v-snackbar v-model="notification.visibility" :color="notification.color" :timeout="notification.timeout">
            {{ notification.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="notification.visibility = false">
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavigationMenu from './shared/NavigationMenu.vue'

export default {
    name: 'App',
    components: {
        NavigationMenu

    },
    data: () => ({

    }),
    methods: {
        ...mapActions('configuration', ['resetConfiguration']),
        ...mapActions('notifications', ['clear']),
        nav() {
            this.login = false;
            return (this.$route.fullPath == '/home'|| this.$route.fullPath == '/conceptos' 
            || this.$route.fullPath == '/vehiculos' || this.$route.fullPath == '/concesionarios'
            || this.$route.fullPath == '/tickets') ? true : false
        }

    },
    computed: {
        ...mapState('notifications', ['notification']),
        ...mapState('configuration', ['configuration']),
        ...mapState('authentication', ['isLoggedIn']),
    },
    mounted() {
        // if(this.isLoggedIn == false){
        //     this.resetConfiguration();
        // }
    },
    beforeMount() {

    },
    watch: {
        // isLoggedIn : function (nvl){
        //     if(this.$route.fullPath != '/login'){
        //         nvl == false ? this.$router.push({'name' : "login"}) : void(0);
        //     }
        // },
    }
}
</script>
