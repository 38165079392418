function getVehicles() {
    return window.axios.get('/api/vehiculo');
}
function updateVehicles({UnidadId,NoEconomico,Placas,Marca,Modelo,ConcesionarioId}){
    return window.axios.patch('/api/vehiculo/update/'+ UnidadId,{'NoEconomico':NoEconomico,'Placas': Placas,'Marca': Marca,'Modelo': Modelo,'ConcesionarioId': ConcesionarioId});
}
function deleteVehicles({UnidadId}){
    return window.axios.delete('api/vehiculo/delete/'+ UnidadId);
}
function createVehicles({NoEconomico,Placas,Marca,Modelo,ConcesionarioId}){
    return window.axios.post('/api/vehiculo/create', {'NoEconomico':NoEconomico,'Placas': Placas,'Marca': Marca,'Modelo': Modelo,'ConcesionarioId': ConcesionarioId});
}

export default {
    getVehicles,
    updateVehicles, 
    deleteVehicles,
    createVehicles
    
} 