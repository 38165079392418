function getConcessionaries(){
    return window.axios.get('/api/concesionario');
}
function updateConcessionaries({ConcesionarioId,Nombre,Ciudad}){
    return window.axios.patch('/api/concesionario/update/'+ ConcesionarioId,{'Nombre': Nombre,'Ciudad': Ciudad});
}
function createConcesssionaries({Nombre,Ciudad}){
    return window.axios.post('/api/concesionario/create',{'Nombre': Nombre,'Ciudad': Ciudad})
}
function deleteConcessionaries({ConcesionarioId}){
    return window.axios.delete('/api/concesionario/delete/'+ ConcesionarioId);
}
export default {
    getConcessionaries,
    updateConcessionaries,
    createConcesssionaries,
    deleteConcessionaries
}

