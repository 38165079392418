const namespaced = true
import api from './../../api/vehicles.js';
import router from '../../routes/index.js';

const state = {
     vehicles: [

     ]

}
const mutations = {
     MUTATION_SET_CONCEPTS(state, data) {
          state.vehicles = data;
     },
     MUTATION_CLEAR_CONCEPTS(state) {
          state.vehicles = [];
     }
}
const actions = {
     getVehicles(context) {
          api.getVehicles().then(function (res) {
               context.commit('MUTATION_SET_CONCEPTS', res.data);

          }).catch(function (error) {
               console.log(error)
               router.push({ name: "login" });
          })
     },
     updateVehicles(context, { UnidadId, NoEconomico, Placas, Marca, Modelo, ConcesionarioId }) {
          api.updateVehicles({ 'UnidadId': UnidadId, 'NoEconomico': NoEconomico, 'Placas': Placas, 'Marca': Marca, 'Modelo': Modelo, 'ConcesionarioId': ConcesionarioId }).then(function (res) {
               console.log(res)
               if (res.status == 200) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
               }

          }).catch(function (error) {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.message }, { root: true });
          })
     },
     deleteVehicles(context, { UnidadId }) {
          api.deleteVehicles({ 'UnidadId': UnidadId }).then(function (res) {
               if (res.status == 200) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                    context.dispatch('getVehicles');

               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },

     createVehicles(context, { NoEconomico, Placas, Marca, Modelo, ConcesionarioId  }) {
          api.createVehicles({  'NoEconomico': NoEconomico, 'Placas': Placas, 'Marca': Marca, 'Modelo': Modelo, 'ConcesionarioId': ConcesionarioId  }).then(function (res) {
               if (res.status == 201) {
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Operación exitosa' }, { root: true });
                    context.dispatch('getVehicles');
               }
          }).catch(function () {
               context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error' }, { root: true });
          })
     },
  
    



}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}   
