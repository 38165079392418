const namespaced = true
import api from './../../api/bitacora.js';
import router from '../../routes/index.js';

const state = {
    bitacora : []
}
const mutations = {
    MUTATION_SET_CONCEPTS(state, data) {
        state.bitacora = data;
    },
    MUTATION_CLEAR_CONCEPTS(state) {
        state.bitacora = [];
    }
}
const actions = {
    getBitacora(context) {
        api.getBitacora().then(function (res) {
            context.commit('MUTATION_SET_CONCEPTS', res.data);

        }).catch(function (error) {
            console.log(error);
            router.push({ name: "login" });

        })
    },

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}