import api from './../../api/files.js';

const namespaced = true

const state = {
    
}
const mutations = {

}
const actions = {
    uploadFile(context, {config, file}){
        api.upload({config, file }).then(async function (res) {
            if(res.status == 200){
                await context.dispatch('notifications/add', {'color' : 'success',  'visibility' : true,  'timeout' : 5000, 'text' : 'Imagen cargada'}, {root: true });      
                await context.dispatch('users/getMyData', {}, {root: true });      
            }
        }).catch(function (error) {
                console.log(error)
        })
    }
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}